<template>
  <div class="resolve" v-if="question">
    <div style="margin:0 20px">
      <Breadcrumb>
        <template slot="restPage">
          <el-breadcrumb-item>
            <router-link
              :to="
                `/tests/${$route.params.testType}/edit/test?testTypeId=${testTypeId}`
              "
            >
              {{ testName }}
            </router-link>
          </el-breadcrumb-item>
          <el-breadcrumb-item>
            <router-link
              :to="
                `/tests/${$route.params.testType}/test/${testTypeId}/section/${sectionId}`
              "
            >
              {{ word.toUpperFirstLetter(sectionName) }}
            </router-link>
          </el-breadcrumb-item>
          <el-breadcrumb-item>
            <router-link
              :to="
                `/tests/${$route.params.testType}/test/${testTypeId}/section/${sectionId}/question`
              "
            >
              <kbd>{{ question.order }}</kbd>
            </router-link>
          </el-breadcrumb-item>
        </template>
      </Breadcrumb>
    </div>
    <Explanation
      :question="question"
      :userAnswer="userAnswer"
      :section="sectionName"
    />
  </div>
</template>

<script>
import { word } from "@ivy-way/material";
import "@/views/toefl/toeflResolve/style.css";
import Breadcrumb from "@/components/Breadcrumb";
import testsApi from "@/apis/tests";
import Explanation from "./ViewQuestion";

export default {
  metaInfo() {
    return {
      title: "Explanation - Ivy-Way Academy"
    };
  },

  components: {
    Breadcrumb,
    Explanation
  },
  props: [],
  // 46Listening 75Speaking
  data() {
    return {
      testName: "",
      sectionName: "",
      question: null,
      question_detail: {},
      question_tags: [],
      userAnswer: null
    };
  },
  computed: {
    word() {
      return word;
    },
    testTypeId(){
      if(this.question){
        return this.$route.params.testTypeId ? this.$route.params.testTypeId : this.question.subject.sat_id;
      }else {
        return null;
      }
    },
    sectionId(){
      if(this.question){
        return this.$route.params.sectionId ? this.$route.params.sectionId: this.question.sat_subject_id;
      } else {
        return null;
      }
    }
  },
  watch: {},

  async mounted() {
    if (this.$route.params.answerId > 0) {
      this.getUserAnswer(this.$route.params.answerId);
    }
    const question = await testsApi.getQuestionNew(
      this.$route.params.testType,
      this.$route.params.questionId
    );
    this.question = question[this.$route.params.testType + "_question"];
    this.testName = question[this.$route.params.testType].full_name;
    this.sectionName = question[this.$route.params.testType].subjects.find(
      subject => subject.id === this.question.subject.id
    ).name;
  },

  methods: {
    async getUserAnswer(answerId) {
      const res = await testsApi.getUserAnswer(
        this.$route.params.testType,
        answerId
      );
      this.userAnswer = res.user_exam_answer;
    }
  }
};
</script>

<style scoped>
.resolve {
  background: #f2f3f5;
  position: absolute;
  top: 0;
  bottom: 0;
  padding-top: 100px;
  width: 100%;
}
</style>
