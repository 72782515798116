var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row cover",staticStyle:{"margin":"0 20px"}},[_c('div',{class:{
      'col-sm-12': _vm.showPassage,
      'col-sm-6': !_vm.showPassage,
      hidden: _vm.section === 'math' || _vm.section === 'math calculator',
      'cover-child': true,
      'passage-box': true
    }},[_c('div',{staticClass:"passage"},[(_vm.question.passage !== null)?_c('div',[_c('ViewPassage',{key:_vm.question,attrs:{"passage":_vm.question.passage,"type":"SAT","section":_vm.section}}),_c('div',{staticClass:"open",on:{"click":function($event){_vm.showPassage = !_vm.showPassage}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showPassage),expression:"!showPassage"}],staticClass:"text"},[_vm._v("展開")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPassage),expression:"showPassage"}],staticClass:"text"},[_vm._v("收起")])])],1):_c('div',[_vm._v(" 資料等待更新。 ")])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showPassage),expression:"!showPassage"}],class:{
      'col-sm-6': true,
      'col-sm-12': _vm.section === 'math' || _vm.section === 'math calculator',
      'cover-child': true,
      'passage-box': true
    }},[_c('div',{staticClass:"question",staticStyle:{"opacity":"1"}},[_c('div',{staticClass:"reading_default"},[_vm._m(0),_c('div',{staticClass:"text-right",staticStyle:{"margin-bottom":"20px"}},[_c('a',{attrs:{"href":`/tests/${_vm.$route.params.testType}/test/${_vm.testTypeId}/section/${_vm.sectionId}/question/${_vm.question.id}/edit`}},[_c('i',{staticClass:"fas fa-edit action-icon"})])]),_c('div',{staticClass:"question-order"},[_c('kbd',[_vm._v(_vm._s(_vm.question.order))])]),_c('div',{staticStyle:{"padding-left":"24px"}},[_c('b',{ref:"questionContent"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.question.content)}})])]),((_vm.question.tags || []).length > 0)?_c('p',[_c('b',[_vm._v("Topics: ")]),_vm._l((_vm.question.tags),function(tag){return _c('el-tag',{key:tag.name,staticClass:"ivy-tag",attrs:{"type":"success","effect":"dark"}},[_vm._v(_vm._s(_vm.helper.displayI18nText(_vm.$i18n.locale, tag.name)))])})],2):_vm._e(),(_vm.options.length > 0 && _vm.answers.length > 0)?_c('div',{ref:"questionOptions"},[(_vm.userAnswer === null && _vm.options.length > 0)?_c('ul',{staticClass:"ivy-question-options"},_vm._l((_vm.options),function(option,index){return _c('li',{key:index,class:_vm.answers[0].answers.indexOf(option.letter) > -1
                  ? 'option text-success'
                  : 'option'},[_c('div',[_c('b',{staticStyle:{"display":"inline-block","width":"50px","text-align":"right","padding-right":"10px"}},[(_vm.answers[0].answers.indexOf(option.letter) > -1)?_c('i',{staticClass:"fas fa-check-circle"}):_vm._e(),_vm._v(" "+_vm._s(option.letter)+". ")])]),_c('div',{staticStyle:{"display":"inline"},domProps:{"innerHTML":_vm._s(option.title)}})])}),0):_vm._e(),(_vm.userAnswer !== null && _vm.options.length > 0)?_c('ul',{staticClass:"ivy-question-options"},_vm._l((_vm.options),function(option,index){return _c('li',{key:index,class:_vm.showAnswer(
                  _vm.answers[0].answers,
                  option.letter,
                  _vm.userAnswer.answers
                )},[_c('div',[_c('b',{staticStyle:{"display":"inline-block","width":"50px","text-align":"right","padding-right":"10px"}},[(
                      _vm.showAnswer(
                        _vm.answers[0].answers,
                        option.letter,
                        _vm.userAnswer.answers
                      ).indexOf('text-success') > -1
                    )?_c('i',{staticClass:"fas fa-check-circle"}):_vm._e(),(
                      _vm.showAnswer(
                        _vm.answers[0].answers,
                        option.letter,
                        _vm.userAnswer.answers
                      ).indexOf('text-danger') > -1
                    )?_c('i',{staticClass:"fas fa-times-circle"}):_vm._e(),_vm._v(" "+_vm._s(option.letter)+". ")])]),_c('div',{staticStyle:{"display":"inline"},domProps:{"innerHTML":_vm._s(option.title)}})])}),0):_vm._e()]):_vm._e(),(_vm.answers.length > 0)?_c('div',{staticClass:"answer_content",staticStyle:{"margin-top":"20px"}},[(_vm.userAnswer !== null)?_c('div',[_c('b',[_vm._v("Your Answer: ")]),_c('b',{class:_vm.userAnswer.is_correct === 1 ? 'text-success' : 'text-danger'},[_vm._v(" "+_vm._s(_vm.getAnswer(_vm.userAnswer.answers))+" ")])]):_vm._e(),_c('div',[_c('b',[_vm._v("Correct Answer: ")]),(
                _vm.answers[0].answers !== undefined &&
                  _vm.answers[0].answers.length > 0
              )?_c('b',{staticClass:"text-success"},[_vm._v(" "+_vm._s(_vm.getCorrectAnswer(_vm.answers))+" ")]):_vm._e()])]):_vm._e(),_c('hr'),_c('div',{ref:"explanation",staticClass:"resolve_content"},[_c('div',{staticClass:"title"},[_vm._v("Explanation:")]),(_vm.question.exp !== '' && _vm.question.exp !== null)?_c('div',{staticClass:"exp",domProps:{"innerHTML":_vm._s(_vm.question.exp)}}):_c('div',{staticClass:"exp"},[_vm._v(" 資料等待更新。 ")])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resolve_content"},[_c('div',{staticClass:"title"},[_vm._v("Question:")])])
}]

export { render, staticRenderFns }