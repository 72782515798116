<template>
  <div class="row cover" style="margin:0 20px">
    <div
      :class="{
        'col-sm-12': showPassage,
        'col-sm-6': !showPassage,
        hidden: section === 'math' || section === 'math calculator',
        'cover-child': true,
        'passage-box': true
      }"
    >
      <div class="passage">
        <div v-if="question.passage !== null">
          <ViewPassage
            :key="question"
            :passage="question.passage"
            type="SAT"
            :section="section"
          />
          <div class="open" @click="showPassage = !showPassage">
            <div class="text" v-show="!showPassage">展開</div>
            <div class="text" v-show="showPassage">收起</div>
          </div>
        </div>
        <div v-else>
          資料等待更新。
        </div>
      </div>
    </div>
    <div
      v-show="!showPassage"
      :class="{
        'col-sm-6': true,
        'col-sm-12': section === 'math' || section === 'math calculator',
        'cover-child': true,
        'passage-box': true
      }"
    >
      <div class="question" style="opacity: 1;">
        <div class="reading_default">
          <div class="resolve_content">
            <div class="title">Question:</div>
          </div>
          <div class="text-right" style="margin-bottom: 20px">
            <a
              :href="
                `/tests/${$route.params.testType}/test/${testTypeId}/section/${sectionId}/question/${question.id}/edit`
              "
            >
              <i class="fas fa-edit action-icon" />
            </a>
          </div>
          <div class="question-order">
            <kbd>{{ question.order }}</kbd>
          </div>
          <div style="padding-left:24px">
            <b ref="questionContent"><span v-html="question.content"></span></b>
          </div>
          <p v-if="(question.tags || []).length > 0">
            <b>Topics: </b>
            <el-tag
              v-for="tag in question.tags"
              :key="tag.name"
              type="success"
              class="ivy-tag"
              effect="dark"
              >{{ helper.displayI18nText($i18n.locale, tag.name) }}</el-tag
            >
          </p>
          <div
            ref="questionOptions"
            v-if="options.length > 0 && answers.length > 0"
          >
            <ul
              class="ivy-question-options"
              v-if="userAnswer === null && options.length > 0"
            >
              <li
                :class="
                  answers[0].answers.indexOf(option.letter) > -1
                    ? 'option text-success'
                    : 'option'
                "
                v-for="(option, index) in options"
                :key="index"
              >
                <div>
                  <b
                    style="display:inline-block;width:50px;text-align: right;padding-right: 10px"
                  >
                    <i
                      v-if="answers[0].answers.indexOf(option.letter) > -1"
                      class="fas fa-check-circle"
                    ></i>
                    {{ option.letter }}.
                  </b>
                </div>
                <div style="display:inline" v-html="option.title"></div>
              </li>
            </ul>
            <ul
              class="ivy-question-options"
              v-if="userAnswer !== null && options.length > 0"
            >
              <li
                :class="
                  showAnswer(
                    answers[0].answers,
                    option.letter,
                    userAnswer.answers
                  )
                "
                v-for="(option, index) in options"
                :key="index"
              >
                <div>
                  <b
                    style="display:inline-block;width:50px;text-align: right;padding-right: 10px"
                  >
                    <i
                      v-if="
                        showAnswer(
                          answers[0].answers,
                          option.letter,
                          userAnswer.answers
                        ).indexOf('text-success') > -1
                      "
                      class="fas fa-check-circle"
                    ></i>
                    <i
                      v-if="
                        showAnswer(
                          answers[0].answers,
                          option.letter,
                          userAnswer.answers
                        ).indexOf('text-danger') > -1
                      "
                      class="fas fa-times-circle"
                    ></i>
                    {{ option.letter }}.
                  </b>
                </div>
                <div style="display:inline" v-html="option.title"></div>
              </li>
            </ul>
          </div>
          <div
            class="answer_content"
            style="margin-top:20px"
            v-if="answers.length > 0"
          >
            <div v-if="userAnswer !== null">
              <b>Your Answer: </b>
              <b
                :class="
                  userAnswer.is_correct === 1 ? 'text-success' : 'text-danger'
                "
              >
                {{ getAnswer(userAnswer.answers) }}
              </b>
            </div>
            <div>
              <b>Correct Answer: </b>
              <b
                class="text-success"
                v-if="
                  answers[0].answers !== undefined &&
                    answers[0].answers.length > 0
                "
              >
                {{ getCorrectAnswer(answers) }}
              </b>
            </div>
          </div>
          <hr />
          <div ref="explanation" class="resolve_content">
            <div class="title">Explanation:</div>
            <div
              class="exp"
              v-html="question.exp"
              v-if="question.exp !== '' && question.exp !== null"
            ></div>
            <div v-else class="exp">
              資料等待更新。
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import { helper } from "@ivy-way/material";
import ViewPassage from "@/components/tests/ViewPassage.vue";

export default {
  props:["question", "userAnswer", "section"],
  components: { ViewPassage },
  computed:{
    testTypeId(){
      if(this.question){
        return this.$route.params.testTypeId ? this.$route.params.testTypeId : this.question.subject.sat_id;
      }else {
        return null;
      }
    },
    sectionId(){
      if(this.question){
        return this.$route.params.sectionId ? this.$route.params.sectionId: this.question.sat_subject_id;
      } else {
        return null;
      }
    },
    helper() {
      return helper;
    },
    options() {
      return (this.question?.options || []);
    },
    answers() {
      return (this.question?.answers || []);
    }
  },
  data() {
    return {
      showPassage: false
    };
  },
  watch: {
    question() {
      this.renderMathjax();
    }
  },
  mounted() {
    this.renderMathjax();
  },

  methods: {
    renderMathjax() {
      if(this.$mathJax.isMathjaxConfig) {
        this.$mathJax.initMathjaxConfig();
      }
      this.$mathJax.MathQueue([
        this.$refs.questionContent,
        this.$refs.questionOptions,
        this.$refs.explanation
      ]);
    },
    getCorrectAnswer(answers){
      let cAnswer = "";
      answers.forEach((item, index) => {
        if (item.answers !== null) {
          if (index !== 0) {
            cAnswer += "," + this.getAnswer(item.answers);
          } else {
            cAnswer = this.getAnswer(item.answers);
          }
        }
      });
      return cAnswer;
    },
    getAnswer(answers) {
      let answer = "";
      answers.forEach((item, index) => {
        if (item !== null) {
          if (index !== 0) {
            answer += "," + item;
          } else {
            answer = item;
          }
        }
      });
      return answer;
    },
    showAnswer(canswers, option, yanswers) {
      if(canswers.indexOf(option) > -1){
        return "option text-success";
      } else {
        if(yanswers.indexOf(option) > -1){
          if(canswers.indexOf(option) > -1) {
            return "option text-success";
          } else {
            return "option text-danger";
          }
        } else {
          return "option";
        }
      }
    }
  }
};
</script>

<style scoped>
.question-order {
  margin: 10px 0 10px 0;
  background-color: #ccc;
}
kbd {
  padding-right: 15px;
  padding-left: 15px;
}
.answer_content {
  justify-content: space-around;
}
.cover {
  height: calc(100vh - 200px);
}
.question-order {
  margin: 20px 0;
  background-color: #ccc;
}
.ivy-question-options {
  font-size: 16px;
  padding-left: 0px;
  margin-bottom: 0px;
  list-style: none;
}
.ivy-question-options .option {
  display: flex;
  font-size: 16px;
  border-bottom: solid 1px #e5e9ef;
  padding: 10px 0;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  align-items:center;
  padding: 10px 0;
  /* justify-content:center; */
}
::v-deep .ivy-question-options .option p {
  margin-bottom: 0 !important;
}
.ivy-tag {
  margin: 0 10px 10px 10px;
}
.hidden {
  display: none;
}
</style>
